<template>
  <div class="orderdetails">
    <img :src="goods_item.img_url" alt class="goods_img" />

    <div class="goods_i_box" v-if="!goods_item.card_key[1] && goods_item">
      <p class="product_name">{{ goods_item.prize_name }}</p>
      <p class="product_name_en">{{ goods_item.prize_name_en }}</p>
      <div class="product_end_time_new" v-if="goods_item.card_end_time">
        <p>卡券兑换有效期：{{goods_item.card_end_time.substring(0,10)}}</p>
        <p>The code is valid until {{goods_item.card_end_time.substring(0,10)}}</p>
      </div>
      <!-- <a class="card_key_D" :href="goods_item.card_key[0]" v-if="goods_item.prize_name == '樊登读书年卡'">
        <p class="card_name"><span>券码(可复制)</span><span class="en">Code (Press to copy)</span></p>
        <p class="card_key ellipsis1">{{ goods_item.card_key[0] }}</p>
      </a> -->
      <div class="card_key_D" @click="copyUrl(goods_item.card_key[0])" v-if="goods_item.prize_name == '樊登读书年卡'">
        <p class="card_name"><span>点击复制</span><span class="en">copy</span></p>
        <p class="card_key ellipsis1">{{ goods_item.card_key[0] }}</p>
      </div>
      <div class="card_key_D" @click="copyUrl(goods_item.card_key[0])" v-else>
        <p class="card_name"><span>券码(可复制)</span><span class="en">Code (Press to copy)</span></p>
        <p class="card_key">{{ goods_item.card_key[0] }}</p>
      </div>
    </div>
    <div class="goods_i_box" v-if="goods_item.card_key[1] && goods_item">
      <p class="product_name">{{ goods_item.prize_name }}</p>
      <p class="product_name_en">{{ goods_item.prize_name_en }}</p>
      <div class="card_key_D">
        <p class="card_name"><span>券码(可复制)</span><span class="en">Code (Press to copy)</span></p>
        <p class="card_key" @click="copyUrl(goods_item.card_key[0])">{{ goods_item.card_key[0] }}</p>
      </div>
      <div class="card_key_D">
        <p class="card_name"><span>券码(可复制)</span><span class="en">Code (Press to copy)</span></p>
        <p class="card_key" @click="copyUrl(goods_item.card_key[1])">{{ goods_item.card_key[1] }}</p>
      </div>
    </div>
    <div class="goods_content" v-html="goods_item.rule_url">{{goods_item.rule_url}}</div>
    <div class="footer_box"></div>
    <div class="footer_btn">
      <div class="btn_cancel back_list" @click="goindex()">
        <span class="btn_one_ch">再次抽奖</span>
        <span class="btn_one_en">Try Again</span>
      </div>
      <div class="btn_confirm back_orderlist" @click="go_order()">
        <span class="btn_one_ch">查看抽奖记录</span>
        <span class="btn_one_en">Check Record</span>
      </div>
    </div>
  </div>
</template>

<script>
var qs = require("qs");

export default {
  name: "index",
  props: {
    msg: String
  },
  data() {
    return {
      goods_item: "",
      prize: "",
      show_popup: false,
      show_loading: false,
    };
  },
  created() {
    this.prize = JSON.parse(localStorage.getItem("prize"));
    console.log(this.prize);
    this.getorder_detail();

  },
  methods: {
    copyUrl(val) {
      // 模拟 输入框
      var cInput = document.createElement("input");
      cInput.value = val;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容

      document.execCommand("copy");
      this.$toast({
        mask: true,
        message: '复制成功',
        zIndex: 999999,
      });
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
    },
    go_order() {
      this.$router.replace({ path: "/H5_luckdrawlist_new" });
    },
    goindex() {
      this.$router.replace({ path: "/H5_luckdraw_new" });
    },
    getorder_detail() {
      var data = qs.stringify({
        ut: localStorage.getItem("ut"),
        userid: localStorage.getItem("userid"),
        id: this.prize.id,
        prize_id: this.prize.prize_id,
      });
      this.$axios({
        method: "post",
        url: this.$url + "/Api/Score/save_info",
        data: data
      }).then(res => {
        console.log("order_detail", res);
        if (res.data.code == -1) {
          this.$toast({
            mask: true,
            message: res.data.msg,
            zIndex: 999999,
          });
        } else {
          this.goods_item = res.data.data;
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import"../../assets/H5.css";
.orderdetails {
  width: 100%;
  height: 100%;
}

.goods_img {
  display: block;
  margin: 0 auto;
  width: 454px;
  height: 454px;
}

#swiper {
  width: 100%;
  height: 419px;
}

.swiper_item {
  width: 100%;
  height: 100%;
}

.swiper_item img {
  width: 100%;
}

.goods_i_box {
  background: #fff;
  box-sizing: border-box;
  padding: 32px 30px 48px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.product_name {
  line-height: 45px;
  font-size: 32px;
  font-weight: bold;
  color: rgba(35, 35, 35, 1);
}

.product_name_en {
  line-height: 33px;
  font-size: 24px;
  margin-bottom: 20px;
}

.product_introduce {
  font-size: 24px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}

.goods_info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
}

.product_price {
  font-size: 20px;
  font-weight: 400;
  color: rgba(35, 35, 35, 1);
}

.card_name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card_name .en {

  font-size: 18px;
}

.card_key_D {
  padding: 0 24px;
  width: 590px;
  height: 88px;
  background: #EDEDFA;
  border-radius: 16px 16px 16px 16px;
  margin-top: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(51, 51, 51, 1);
  font-size: 26px;
}

.card_key {
  width: 370px;
  text-align: right;
  font-size: 28px;
}

.product_price span {
  font-size: 34px;
  color: rgba(236, 100, 0, 1);
}

.product_stock {
  font-size: 24px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}

.style_pagination {
  width: 91px;
  height: 40px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 22px;
  font-size: 22px;
  line-height: 40px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  right: 20px;
  left: unset;
}

.goods_content {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  padding: 0px 30px 40px 30px;
  font-size: 24px;
  font-weight: 400;
  word-wrap: break-word;
  color: rgba(35, 35, 35, 1);
}

.goods_content>>>img {
  width: 100%;
}

.footer_box {
  width: 100%;
  height: 140px;
}

.footer_btn {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: 24px 30px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid#EDEDFA;
  ;
}

.footer_btn div {
  width: 335px;
  height: 72px;
  line-height: 1;
  border-radius: 30px 4px 30px 4px;
}

.footer_btn .btn_cancel {
  background: #fff;
  border: 1px solid #02005D;
}

.footer_btn .btn_cancel .btn_one_ch {
  font-size: 26px;
  font-weight: bold;
  color: #02005D;
}

.footer_btn .btn_cancel .btn_one_en {
  font-size: 12px;
  color: #02005D;
  margin-top: 4px;
}

.footer_btn .btn_confirm {
  background: #02005D;
}

.footer_btn .btn_confirm .btn_one_ch {
  font-size: 26px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}

.footer_btn .btn_confirm .btn_one_en {
  font-size: 16px;
  margin-top: 4px;
  color: rgba(255, 255, 255, 1);
}

button:focus {
  outline: none !important;
  z-index: 10000;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
}
</style>
  